<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createPost"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.posts.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.posts.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :server-items-length="pagination.totalItems"
                    :sort-by="sortby"
                    :multi-sort="true"
                    @click:row="editItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.attributes.image="{ value }">
                        <!--                        {{ images[value] ? images[value] : "No image "+value }}-->
                        <v-img
                            :src="getImageRoute + value"
                            :aspect-ratio="4 / 3"
                            height="90px"
                            width="120px"
                            class="my-1"
                        />
                    </template>

                    <template #item.attributes.title="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.attributes.isActive="{ value }">
                        <v-icon
                            small
                            :color="value === 1 ? 'green' : 'red'"
                        >
                            {{ value === 1 ? 'mdi-check' : 'mdi-close' }}
                        </v-icon>
                    </template>

                    <template v-slot:item.attributes.created-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>
                    <template #item.attributes.updated-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click.stop="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchPosts"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog ref="confirm" />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios';
// import RoleCreate from '@/components/admin/roles/rolesCreate'
import ConfirmDialog from '@/components/common/ConfirmDialog';
import { debounce } from 'debounce';
import { mapGetters } from 'vuex';

// import * as dateHelper from '@/helpers/date'

export default {
    components: { /* RoleCreate, */ ConfirmDialog },
    props: {
        companyId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            urlMain: '/posts',
            /* dialog: false, */
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.posts.image'),
                    value: 'attributes.image'
                },
                {
                    text: this.$t('admin.posts.titleAtt'),
                    value: 'attributes.title'
                },
                {
                    text: this.$t('admin.posts.url'),
                    value: 'attributes.url'
                },
                {
                    text: this.$t('admin.posts.category'),
                    value: 'attributes.category-id'
                },
                {
                    text: this.$t('admin.posts.order'),
                    value: 'attributes.order'
                },
                {
                    text: this.$t('admin.posts.lang'),
                    value: 'attributes.lang'
                },
                {
                    text: this.$t('common.enabled'),
                    value: 'attributes.isActive'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'attributes.created-at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'attributes.updated-at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemSelected: null,
            sortby: ['attributes.order'],
            pagination: {
                totalItems: 10
            },
            options: {
                itemsPerPage: 10
            }
        };
    },
    watch: {
        options: {
            handler(newValue, oldValue) {
                this.fetchPosts(newValue);

                if (newValue.sortBy && newValue.sortBy.length > 1) {
                    this.$store.dispatch('snackbar/success', this.$t('common.multisort'));
                }
            }
        },
        search: {
            handler(newValue) {
                this.fetchPosts();
            }
        },

        dialog(val) {
            val || this.close();
        }
    },
    /* computed: {
      ...mapGetters(['role'])
  }, */

    created() {},

    mounted() {},

    methods: {
        fetchPosts: debounce(function (e) {
            this.fetchPostsFinal(e);
        }, 500),

        async fetchPostsFinal(data = this.options) {
            /* console.log('********** data ******')
  console.log(data) */

            const optionalsParams = [];
            if (this.search) {
                optionalsParams['filter[search]'] = this.search;
            }

            if (data?.sortBy?.length) {
                const joinSort = data.sortBy.map(
                    (value, index) => (data.sortDesc[index] ? '-' : '') + value.replace('attributes.', '')
                );

                optionalsParams.sort = joinSort.join(',');
            }

            try {
                const posts = await axios.get(this.urlMain, {
                    params: {
                        'page[size]': data.itemsPerPage,
                        'page[number]': data.page,
                        'filter[company]': this.companyId,
                        ...optionalsParams
                    }
                });
                this.initialize(posts.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },
        initialize($data) {
            this.items = $data.data;

            this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },
        createPost() {
            this.$router.push('/app/admin/posts/create/' + this.companyId);
        },
        /* setRole($data) {
      this.itemsRoles = $data;
  }, */
        editItem(item) {
            // this.itemSelected = item;
            // this.dialog = true;

            this.$router.push({ name: 'PostsCreate', params: { item, id: this.companyId + '' } });
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios.delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    this.fetchPosts();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'));
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error); // "invalid_credentials"
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            }
            this.$store.dispatch('loading/end');
        },
        async deleteItem(item) {
            if (await this.$refs.confirm.open(this.$t('common.confirmTitle'), this.$t('common.confirmMSGDelete'))) {
                this.deleteItemConfirmed(item);
            }
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.itemSelected = null;
            });
        }
    },
    computed: {
        getImageRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=posts/images/';
        }
    }
};
</script>
